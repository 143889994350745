<template>
  <div>
    <v-row class="mt-3 py-3 mx-3" justify="center" align="center" no-gutters>
      <!-- <v-select
        :label="$t('master_data.student.school_year')"
        :items="schoolYearList"
        :item-text="schoolYearText"
        item-value="id"
        hide-details
        outlined
        dense
        class="caption select-150"
      ></v-select> -->

      <v-btn
        :loading="loadingDownloadExcel"
        :disabled="dataMutation.length === 0"
        class="gradient-primary caption"
        dark
        depressed
      >
        <download-excel
          :fetch="downloadExcel"
          :fields="fields"
          :name="`${getMenuName()} ${$t('master_data.student.tab2_title')}.xls`"
        >
          <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2 m">{{
            $t("app.download")
          }}</span>
          <v-icon>mdi-cloud-download</v-icon>
        </download-excel>
      </v-btn>

      <div class="subtitle-2 font-weight-bold d-inline ml-3">
        {{ total }} {{ namaMenu }}
      </div>
      <v-spacer></v-spacer>

      <v-text-field
        v-model="queryData.search"
        :label="$t('app.search')"
        outlined
        dense
        hide-details
        prepend-inner-icon="mdi-magnify"
        style="max-width: 250px"
      />
    </v-row>

    <v-sheet>
      <v-row
        v-if="multipleId.length > 0"
        class="mr-2 mb-3"
        justify="end"
        no-gutters
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
              v-on="on"
              depressed
              icon
              text
              class="error"
              @click="setCancelMutation()"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("master_data.student.cancel_selected_mutation") }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
              v-on="on"
              depressed
              icon
              text
              class="error ml-2"
              @click="setDeleteEmployee(null)"
            >
              <v-icon color="white">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>
            {{ $t("master_data.student.delete_selected_mutation") }}
          </span>
        </v-tooltip>
      </v-row>

      <v-data-table
        v-model="multipleId"
        :headers="tableHeaders"
        :items="dataMutation"
        :loading="loading"
        :options.sync="options"
        hide-default-footer
        show-select
        disable-pagination
        class="elevation-0"
      >
        <template v-slot:item.date_out="{ item }">
          {{ dateFormat(item.date_out) }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                icon
                color="error"
                v-on="on"
                @click="setCancelMutation(item.id)"
                :disabled="multipleId.length > 0"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("master_data.student.cancel_mutation") }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                icon
                color="error"
                v-on="on"
                @click="setDeleteEmployee(item.id)"
                :disabled="multipleId.length > 0"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("master_data.delete_mutation") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <Pagination
        v-if="dataMutation.length > 0"
        :length="length"
        :total="total"
        :current-page="queryData.page"
        :limit="queryData.limit"
        :handler="paginateChange"
      />

      <ModalConfirm
        :model="isModalConfirm"
        :title="$t('master_data.mutation')"
        :close="toggleModalConfirm"
        :save="cancelMutation"
        :loadingBtn="loadingBtnConfirm"
        :content="$t('master_data.cancel_mutation')"
      />
      <ModalConfirm
        :model="dialogDelete"
        :title="$t('master_data.delete_mutation')"
        :close="toggleModalConfirm"
        :save="deleteEmployee"
        :loadingBtn="loadingDelete"
        :content="$t('master_data.delete_mutation_confirm')"
      />
    </v-sheet>
  </div>
</template>

<script>
import i18n from "@/i18n";
import moment from "moment/moment";
moment.locale("id");

import { get_school_year_list } from "@/api/admin/schoolClass";
import { get_mutation_list, update_mutation } from "@/api/admin/master";
import { deleteEmployeesMutation } from "@/api/admin/master/employee";

let typingTimer;
const doneTypingInterval = 500;

export default {
  components: {
    Pagination: () => import("@/components/Pagination"),
    ModalConfirm: () => import("../components/ModalConfirm")
  },
  filters: {
    dateTimeFormat: value => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY, HH:mm:ss");
    }
  },
  props: {
    namaMenu: String,
    menuType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      schoolYearList: [],
      loading: false,
      loadingDownloadExcel: false,
      dialogDelete: false,
      loadingDelete: false,
      dataMutation: [],
      multipleId: [],
      cancelMutationList: [],
      isModalConfirm: false,
      loadingBtnConfirm: false,
      idMutation: null,
      options: {},
      total: 0,
      length: 0,
      fields: {
        [i18n.t("master_data.table.name")]: "name",
        [i18n.t("master_data.table.date")]: {
          field: "date_out",
          callback: value => this.dateFormat(value)
        },
        [i18n.t("master_data.table.phone")]: {
          field: "phone",
          callback: value => this.preprocessLongNum(value)
        },
        [i18n.t("master_data.table.email")]: "email",
        [i18n.t("master_data.table.description")]: "description"
      },
      tableHeaders: [
        {
          text: i18n.t("master_data.table.name"),
          align: "left",
          value: "name"
        },
        {
          text: i18n.t("master_data.table.phone"),
          value: "phone"
        },
        {
          text: i18n.t("master_data.table.email"),
          value: "email"
        },
        {
          text: i18n.t("master_data.table.date"),
          value: "date_out"
        },

        {
          text: i18n.t("master_data.table.description"),
          value: "description",
          sortable: false
        },
        {
          text: i18n.t("app.action"),
          sortable: false,
          value: "action",
          align: "center",
          width: 120
        }
      ],
      queryData: {
        type: this.menuType.toUpperCase(),
        limit: 10,
        page: 1,
        sort: [],
        order: "DESC",
        search: ""
      }
    };
  },
  watch: {
    "$route.query.tab": {
      immediate: true,
      handler() {
        if (this.$route.query.tab == 1) this.getData();
      }
    },
    "queryData.search"() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.getData();
      }, doneTypingInterval);
    },
    options: {
      handler(param) {
        if (param.sortBy.length != 0) {
          this.loading = true;
          this.queryData.sort = [param.sortBy[0]];
          this.queryData.page = param.page;
          this.queryData.limit = param.itemsPerPage;
          this.getData();
        }
      }
    }
  },
  async created() {
    const res = await get_school_year_list();
    this.schoolYearList = res.data.data;
  },
  methods: {
    preprocessLongNum(value) {
      if (String(value).startsWith("0x")) {
        return value;
      }
      if (!isNaN(value) && value != "") {
        return `="${value}"`;
      }
      return value;
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY");
    },
    deleteEmployee() {
      this.loadingDelete = true;
      const body = { employees: this.idMutation };
      deleteEmployeesMutation(body)
        .then(res => {
          if (res.data.code) {
            this.snackBar(
              true,
              this.$i18n.t(
                "master_data.student.response.success_delete_mutation"
              )
            );
            this.toggleModalConfirm("save");
          } else {
            this.snackBar(false, res.data.message);
          }
          this.loadingDelete = false;
        })
        .catch(error => {
          console.error("deleteEmployee()\n", error);
          this.loadingDelete = false;
          this.dialogDelete = false;
        });
    },
    getMenuName() {
      return this.$route.name == "Teacher"
        ? this.$i18n.t("routes.teacher")
        : this.$i18n.t("routes.staff");
    },
    toggleModalConfirm(type) {
      if (type == "save") {
        this.getData();
        this.idMutation = null;
        this.multipleId = [];
      }
      this.isModalConfirm = false;
      this.dialogDelete = false;
    },
    setCancelMutation(id) {
      this.setIdMutation(id);
      this.isModalConfirm = true;
    },
    setDeleteEmployee(id) {
      this.setIdMutation(id);
      this.dialogDelete = true;
    },
    setIdMutation(id) {
      if (id) {
        this.idMutation = [id];
      } else {
        this.idMutation = this.multipleId.map(r => r.id);
      }
    },
    cancelMutation() {
      this.loadingBtnConfirm = true;
      let data = {};
      data.type = this.menuType;
      data.id = this.idMutation;

      update_mutation(data)
        .then(res => {
          if (res.data.code) {
            this.snackBar(
              true,
              this.$i18n.t("master_data.success_cancel_mutation")
            );
            this.toggleModalConfirm("save");
          } else {
            this.snackBar(false, res.data.message);
          }
          this.loadingBtnConfirm = false;
        })
        .catch(() => (this.loadingBtnConfirm = false));
    },
    async downloadExcel() {
      this.loadingDownloadExcel = true;
      const query = JSON.parse(JSON.stringify(this.queryData));
      query.limit = this.total;
      query.page = 1;

      const res = await get_mutation_list(query);
      this.loadingDownloadExcel = false;

      const setData = [];
      const data = res.data.data.data;

      data.map(r => {
        r.date_out = moment(r.date_out).format("DD MMM YYYY");
        setData.push(r);
      });

      if (res.data.code) {
        if (data.length == 0) {
          this.snackBar(false, this.$i18n.t("app.data_not_found"));
        } else return setData;
      }
    },
    schoolYearText: item => {
      if (item.id !== "all") {
        return (
          item.start_year + " / " + item.end_year + ", semester" + item.semester
        );
      } else {
        return item.name;
      }
    },
    getData() {
      this.dataMutation = [];
      this.loading = true;
      get_mutation_list(this.queryData)
        .then(res => {
          this.loading = false;
          const r = res.data.data;
          this.total = r.total;
          this.length = r.last_page;
          this.dataMutation = r.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.getData();
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    }
  }
};
</script>
